<template>
  <div>
    <Toast position="top-right" />

    <div class="card">
      <div class="card-body p-0 py-4">
        <div class="aut-area-exibir">
          <div class="menus-container">
            <b-tabs>
              <b-tab
                v-for="canal in canais_disponiveis"
                :key="canal.value"
                :title="canal.text"
                class="p-4"
              >
                <div class="header-menus-container">
                  <h3>
                    Itens do Menu -
                    <span style="color: #ffa800">{{ canal.text }}</span>
                  </h3>
                  <div class="botoes-container">
                    <b-button
                      variant="success mr-2 text-uppercase"
                      @click="salvarAlteracoes"
                    >
                      Salvar Alterações
                    </b-button>
                  </div>
                </div>
                <draggable
                  class="list-group"
                  tag="ul"
                  v-model="menuItens[canal.value]"
                  v-bind="dragOptions"
                  @start="drag = true"
                  @end="drag = false"
                >
                  <transition-group
                    type="transition"
                    :name="!drag ? 'flip-list' : null"
                  >
                    <li
                      class="list-group-item"
                      v-for="(item, idx) in menuItens[canal.value]"
                      :key="
                        item.chatbot_menu_item_id
                          ? item.chatbot_menu_item_id
                          : idx
                      "
                      :class="
                        (item.cmi_ativo ? 'ativo ' : 'inativo ') +
                        (editavel == item.chatbot_menu_item_id
                          ? 'editando'
                          : '')
                      "
                    >
                      <div class="inner-menu-item">
                        <div class="flexer-container">
                          <div class="drag-icon">
                            <i class="fa-solid fa-ellipsis-vertical"></i>
                            <i class="fa-solid fa-ellipsis-vertical"></i>
                          </div>
                          <div class="coluna-item">
                            <div class="flexer-container">
                              <div class="flexer-container">
                                <div class="coluna-item" style="width: 250px">
                                  <h6 class="titulo">Título do Menu</h6>
                                  <div class="coluna-item-titulo">
                                    {{ idx + 1 }} - {{ item.cmi_titulo }}
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="item.cmi_titulo"
                                    v-if="editavel == item.chatbot_menu_item_id"
                                  />
                                </div>
                              </div>
                              <div class="coluna-item" style="width: 250px">
                                <h6 class="titulo">Fluxo Relacionado</h6>
                                <div class="coluna-item-titulo">
                                  <span>
                                    {{ fluxoDoItem(item.chatbot_fluxo_id) }}
                                  </span>

                                  <i
                                    class="fas fa-external-link-alt"
                                    style="font-size: 10px"
                                    v-if="item.chatbot_fluxo_id"
                                    @click="
                                      abrirFluxoEmNovaAba(item.chatbot_fluxo_id)
                                    "
                                  ></i>
                                </div>

                                <b-form-select
                                  v-if="editavel == item.chatbot_menu_item_id"
                                  v-model="item.chatbot_fluxo_id"
                                  :options="fluxoOptions"
                                ></b-form-select>
                              </div>
                            </div>
                            <div class="flexer-container">
                              <div class="coluna-item w-100">
                                <h6 class="titulo">Descrição</h6>
                                <div
                                  v-if="editavel == item.chatbot_menu_item_id"
                                >
                                  <textarea
                                    class="form-control"
                                    v-model="item.cmi_descricao"
                                    :maxlength="72"
                                  ></textarea>
                                  <p
                                    :class="{
                                      'text-danger':
                                        check(item.cmi_descricao) == 0
                                    }"
                                  >
                                    {{ instruction(item.cmi_descricao) }}
                                  </p>
                                </div>
                                <div v-else>
                                  {{ item.cmi_descricao }}
                                </div>
                              </div>
                            </div>
                            <div class="flexer-container">
                              <div class="coluna-item w-100">
                                <h6 class="titulo">Palavras-Chave</h6>
                                <Chips
                                  v-model="item.cmi_palavras_chave"
                                  separator=","
                                  class="w-100"
                                  v-if="editavel == item.chatbot_menu_item_id"
                                >
                                  <template #chip="slotProps">
                                    <div>
                                      <span>{{ slotProps.value }}</span>
                                    </div>
                                  </template></Chips
                                >
                                <div class="badges-container" v-else>
                                  <b-badge
                                    v-if="
                                      !item.cmi_palavras_chave ||
                                      item.cmi_palavras_chave.length == 0
                                    "
                                    pill
                                    style="
                                      margin-top: 1px;
                                      margin-right: 3px;
                                      background: #b0b3b6 !important;
                                    "
                                    variant="primary"
                                    >Nenhuma palavra-chave cadastrada</b-badge
                                  >

                                  <b-badge
                                    v-for="(
                                      palavra, idx
                                    ) in item.cmi_palavras_chave"
                                    :key="palavra + idx"
                                    style="margin-top: 1px; margin-right: 3px"
                                    pill
                                    variant="primary"
                                    >{{ palavra.toLowerCase() }}</b-badge
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="btn-group float-right">
                          <ToggleButton
                            onLabel="Ativo"
                            offLabel="Inativo"
                            v-model="item.cmi_ativo"
                            class="w-100 text-uppercase mr-3"
                            :disabled="editavel != item.chatbot_menu_item_id"
                          />
                          <b-button
                            v-if="editavel == item.chatbot_menu_item_id"
                            class="btn-success"
                            variant="primary"
                            @click="editarMenuItem(item)"
                            style="border-radius: 5px 0px 0px 5px"
                            >Alterar</b-button
                          >
                          <b-button
                            v-if="editavel == item.chatbot_menu_item_id"
                            class="btn-danger"
                            variant="primary"
                            @click="cancelarEdicao(item, canal.value)"
                            >Cancelar</b-button
                          >
                          <b-button
                            type="button"
                            variant="secondary"
                            @click="ativarModoEdicao(item)"
                            v-if="editavel != item.chatbot_menu_item_id"
                            :disabled="modoEdicao"
                          >
                            <i class="fas fa-edit"></i>
                          </b-button>
                          <b-button
                            type="button"
                            variant="danger"
                            @click="excluirItem(item, canal.value)"
                            v-if="editavel != item.chatbot_menu_item_id"
                            :disabled="modoEdicao"
                          >
                            <i class="fas fa-trash"></i>
                          </b-button>
                        </div>
                      </div>
                    </li>
                  </transition-group>
                </draggable>
                <button
                  class="novo-menu-btn"
                  @click="criarNovoMenu(canal.value)"
                >
                  + Novo Menu
                </button>
              </b-tab></b-tabs
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import draggable from "vuedraggable";
import ToggleButton from "primevue/togglebutton";
import Chips from "primevue/chips";

import ApiService from "@/core/services/api.service";
import API_LINKS from "../automacao/api.links";

export default {
  components: {
    Toast: () => import("primevue/toast"),
    draggable,
    ToggleButton,
    Chips
  },
  data() {
    return {
      canais_possiveis: [
        { value: "AGENTE_SITE_WIDGET", text: "Widget" },
        { value: "WHATSAPP_OFICIAL", text: "WhatsApp Oficial" },
        { value: "INSTAGRAM", text: "Instagram" }
      ],
      canais_disponiveis: [{ value: "PRINCIPAL", text: "Principal" }],
      drag: false,
      menuItens: {
        PRINCIPAL: [],
        AGENTE_SITE_WIDGET: [],
        WHATSAPP_OFICIAL: [],
        INSTAGRAM: []
      },
      menuConfiguracao: {},
      editavel: -1,
      menuSelecionadoEstadoInicial: {},
      modoEdicao: false,
      fluxos: [],
      menuInicial: [],
      ultimoIdFalsoCriado: 1e9 + 7
    };
  },
  methods: {
    check(message) {
      return message ? 72 - message.length : 1;
    },
    instruction(message) {
      return message == "" || !message
        ? "Máximo de 72 caracteres"
        : "Restam " + this.check(message) + " caracteres";
    },
    abrirFluxoEmNovaAba(fluxoId) {
      const fluxo = this.acharFluxoPeloId(fluxoId);
      const routeData = this.$router.resolve({
        name: "automacao.fluxo.editar",
        query: {
          fluxo_id: fluxo.chatbot_fluxo_id
        }
      });
      window.open(routeData.href, "_blank");
    },
    acharFluxoPeloId(fluxoId) {
      return this.fluxos.find((fluxo) => fluxo.chatbot_fluxo_id == fluxoId);
    },
    ordernarInativosParaFimDaLista(canal_tipo) {
      this.menuItens[canal_tipo].sort((a, b) => {
        if (a.cmi_ativo && !b.cmi_ativo) return -1;
        if (!a.cmi_ativo && b.cmi_ativo) return 1;
        return 0;
      });
    },
    criarNovoMenu(canal_tipo) {
      if (this.editavel != -1) {
        Swal.fire({
          title: "Você tem um item em edição",
          text: "Você precisa salvar ou cancelar as alterações antes de criar um novo item",
          icon: "warning",
          confirmButtonText: "Ok"
        });
        return;
      }
      this.editavel = this.ultimoIdFalsoCriado;
      const currentBiggestCmiOrdem = Math.max.apply(
        Math,
        this.menuItens[canal_tipo].map(function (o) {
          return o.cmi_ordem;
        })
      );
      const novoMenu = {
        chatbot_menu_item_id: this.ultimoIdFalsoCriado,
        chatbot_fluxo_id: -1,
        cmi_titulo: "Título do Menu",
        cmi_ativo: true,
        cmi_ordem: currentBiggestCmiOrdem
      };
      this.ultimoIdFalsoCriado++;

      if (this.fluxos.length > 0) {
        novoMenu.chatbot_fluxo_id = this.fluxos[0].chatbot_fluxo_id;
      }
      this.menuItens[canal_tipo].push(novoMenu);
      this.ordernarInativosParaFimDaLista(canal_tipo);
    },
    salvarAlteracoes() {
      if (this.editavel != -1) {
        Swal.fire({
          title: "Você tem um item em edição",
          icon: "warning",
          confirmButtonText: "Ok"
        });
        return;
      }
      Swal.fire({
        title: "Deseja salvar as alterações?",
        text: "Você não poderá desfazer esta ação!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, salvar alterações!",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            menu: {}
          };
          const before_principal_menu = JSON.parse(
            JSON.stringify(this.menuItens.PRINCIPAL)
          );
          const before_whatsapp_menu = JSON.parse(
            JSON.stringify(this.menuItens.WHATSAPP_OFICIAL)
          );
          const before_instagram_menu = JSON.parse(
            JSON.stringify(this.menuItens.INSTAGRAM)
          );
          const before_widget_menu = JSON.parse(
            JSON.stringify(this.menuItens.AGENTE_SITE_WIDGET)
          );

          payload.menu.PRINCIPAL = this.menuItens.PRINCIPAL.map(
            (item, index) => {
              if (item.chatbot_menu_item_id >= 1e9 + 7) {
                item.chatbot_menu_item_id = null;
              }
              item.cmi_palavras_chave = JSON.stringify(item.cmi_palavras_chave);
              return {
                ...item,
                cmi_ordem: index + 1
              };
            }
          );

          payload.menu.WHATSAPP_OFICIAL = this.menuItens.WHATSAPP_OFICIAL.map(
            (item, index) => {
              if (item.chatbot_menu_item_id >= 1e9 + 7) {
                item.chatbot_menu_item_id = null;
              }
              item.cmi_palavras_chave = JSON.stringify(item.cmi_palavras_chave);
              return {
                ...item,
                cmi_ordem: index + 1
              };
            }
          );

          payload.menu.AGENTE_SITE_WIDGET =
            this.menuItens.AGENTE_SITE_WIDGET.map((item, index) => {
              if (item.chatbot_menu_item_id >= 1e9 + 7) {
                item.chatbot_menu_item_id = null;
              }
              item.cmi_palavras_chave = JSON.stringify(item.cmi_palavras_chave);
              return {
                ...item,
                cmi_ordem: index + 1
              };
            });

          payload.menu.INSTAGRAM = this.menuItens.INSTAGRAM.map(
            (item, index) => {
              if (item.chatbot_menu_item_id >= 1e9 + 7) {
                item.chatbot_menu_item_id = null;
              }
              item.cmi_palavras_chave = JSON.stringify(item.cmi_palavras_chave);
              return {
                ...item,
                cmi_ordem: index + 1
              };
            }
          );

          this.menuItens.PRINCIPAL = before_principal_menu;
          this.menuItens.WHATSAPP_OFICIAL = before_whatsapp_menu;
          this.menuItens.INSTAGRAM = before_instagram_menu;
          this.menuItens.AGENTE_SITE_WIDGET = before_widget_menu;

          ApiService.post(API_LINKS.chatbot_menu, payload).then((response) => {
            if (response.data?.sucesso == false) {
              Swal.fire(
                "Erro ao salvar!",
                "Ocorreu um erro ao salvar as alterações. Tente novamente mais tarde.",
                "error"
              );
              return;
            } else if (response.status == 200) {
              Swal.fire(
                "Alterações salvas!",
                "As alterações foram salvas com sucesso.",
                "success"
              );
            }
          });
        }
      });
    },

    fluxoDoItem(id) {
      let fluxo = this.fluxos.find((f) => f.chatbot_fluxo_id == id);
      return fluxo ? fluxo.cfl_titulo : "";
    },
    editarMenuItem(item) {
      this.editavel = -1;
      this.modoEdicao = false;
    },
    excluirItem(item, canal) {
      this.menuItens[canal] = this.menuItens[canal].filter(
        (i) => i.chatbot_menu_item_id != item.chatbot_menu_item_id
      );
    },
    cancelarEdicao(item, canal) {
      this.editavel = -1;
      const menuItemId = item.chatbot_menu_item_id;

      const menuItem = this.menuItens[canal].find(
        (item) => item.chatbot_menu_item_id == menuItemId
      );
      menuItem.cmi_titulo = this.menuSelecionadoEstadoInicial.cmi_titulo;
      menuItem.cmi_descricao = this.menuSelecionadoEstadoInicial.cmi_descricao;
      menuItem.cmi_ativo = this.menuSelecionadoEstadoInicial.cmi_ativo;
      menuItem.chatbot_fluxo_id =
        this.menuSelecionadoEstadoInicial.chatbot_fluxo_id;
      this.modoEdicao = false;
    },
    ativarModoEdicao(item) {
      this.editavel = item.chatbot_menu_item_id;
      this.menuSelecionadoEstadoInicial = JSON.parse(JSON.stringify(item));
      this.modoEdicao = true;
    },
    pegarRecursosDaConta() {
      ApiService.get(API_LINKS.recursosAtivos).then((res) => {
        this.recursosDeCanaisValidos(res.data.data.map((v) => v.rec_codigo));
      });
    },
    pegarConfiguracoesMenu() {
      ApiService.get(API_LINKS.chatbot_menu).then((res) => {
        if (res.data?.sucesso === false) {
          Swal.fire({
            title: "Erro!",
            text: res.data.errors,
            icon: "error",
            confirmButtonText: "Ok"
          });
          return;
        }
        if (!res.data.menu) {
          return;
        }
        this.menuInicial = [
          {
            chatbot_menu_configuracao_id: 5,
            conta_id: 2,
            cmc_msg_menu:
              "Olá! Eu sou um assistente virtual, digite o número correspondente ao serviço que você precisa de ajuda:",
            cmc_msg_item_invalido:
              "Eu não estou conseguindo entender sobre o quê você gostaria de conversar 🙁.\nPara ver todos os serviços oferecidos, digite a palavra *menu*\n",
            cmc_msg_erro:
              "Houve um erro ao consultar nossa base de dados. Por favor, tente novamente mais tarde.",
            cmc_msg_fim_atendimento: "Obrigado pela preferência. Tchau",
            cmc_qtd_max_resultados: 20,
            cmc_ativo: true,
            cmc_arr_numeros: null,
            cmc_acao_inatividade: 3582,
            cmc_tempo_inatividade: 3,
            cmc_canal: null,
            con_login: "w3si",
            con_senha: "J8c9Fx4zXpsLzDK8LlZ4"
          },
          {
            chatbot_menu_configuracao_id: 500,
            conta_id: 2,
            cmc_msg_menu:
              "Olá! Eu sou um assistente virtual, digite o número correspondente ao serviço que você precisa de ajuda:",
            cmc_msg_item_invalido:
              "Eu não estou conseguindo entender sobre o quê você gostaria de conversar 🙁.\nPara ver todos os serviços oferecidos, digite a palavra *menu*\n",
            cmc_msg_erro:
              "Houve um erro ao consultar nossa base de dados. Por favor, tente novamente mais tarde.",
            cmc_msg_fim_atendimento: "Obrigado pela preferência. Tchau",
            cmc_qtd_max_resultados: 20,
            cmc_ativo: true,
            cmc_arr_numeros: null,
            cmc_acao_inatividade: 346,
            cmc_tempo_inatividade: 20,
            cmc_canal: "AS",
            con_login: "w3si",
            con_senha: "J8c9Fx4zXpsLzDK8LlZ4"
          },
          {
            chatbot_menu_configuracao_id: 872,
            conta_id: 2,
            cmc_msg_menu:
              "Olá! Eu sou um assistente virtual, digite o número correspondente ao serviço que você precisa de ajuda:",
            cmc_msg_item_invalido:
              "Eu não estou conseguindo entender sobre o quê você gostaria de conversar 🙁.\nPara ver todos os serviços oferecidos, digite a palavra *menu*",
            cmc_msg_erro:
              "Houve um erro ao consultar nossa base de dados. Por favor, tente novamente mais tarde.",
            cmc_msg_fim_atendimento: "Obrigado pela preferência.",
            cmc_qtd_max_resultados: 20,
            cmc_ativo: false,
            cmc_arr_numeros: null,
            cmc_acao_inatividade: null,
            cmc_tempo_inatividade: 15,
            cmc_canal: "AW",
            con_login: "w3si",
            con_senha: "J8c9Fx4zXpsLzDK8LlZ4"
          },
          {
            chatbot_menu_configuracao_id: 905,
            conta_id: 2,
            cmc_msg_menu:
              "Olá! Eu sou um assistente virtual, digite o número correspondente ao serviço que você precisa de ajuda:",
            cmc_msg_item_invalido:
              "Eu não estou conseguindo entender sobre o quê você gostaria de conversar 🙁.\nPara ver todos os serviços oferecidos, digite a palavra *menu*",
            cmc_msg_erro:
              "Houve um erro ao consultar nossa base de dados. Por favor, tente novamente mais tarde.",
            cmc_msg_fim_atendimento: "Obrigado pela preferência.",
            cmc_qtd_max_resultados: 20,
            cmc_ativo: false,
            cmc_arr_numeros: null,
            cmc_acao_inatividade: null,
            cmc_tempo_inatividade: 15,
            cmc_canal: "AI",
            con_login: "w3si",
            con_senha: "J8c9Fx4zXpsLzDK8LlZ4"
          }
        ];

        const hashmap = {};
        this.menuInicial.forEach((item) => {
          let sigla = "PRINCIPAL";
          if (item.cmc_canal == "AW") {
            sigla = "WHATSAPP_OFFICIAL";
          } else if (item.cmc_canal == "AI") {
            sigla = "INSTAGRAM";
          } else if (item.cmc_canal == "AS") {
            sigla = "AGENTE_SITE_WIDGET";
          }
          hashmap[item.chatbot_menu_configuracao_id] = sigla;
        });

        for (let key in res.data.menu) {
          res.data.menu[key] = res.data.menu[key].map((item) => {
            return {
              name: item.cmi_titulo,
              order: item.cmi_ordem,
              editavel: false,
              ...item
            };
          });
        }
        this.menuItens = res.data.menu;
        [
          "PRINCIPAL",
          "WHATSAPP_OFICIAL",
          "INSTAGRAM",
          "AGENTE_SITE_WIDGET"
        ].forEach((key) => {
          this.ordernarInativosParaFimDaLista(key);
          this.menuItens[key] = this.menuItens[key].map((item) => {
            return {
              ...item,
              cmi_palavras_chave: JSON.parse(item.cmi_palavras_chave)
            };
          });
        });
      });
    },
    pegarFluxos() {
      ApiService.get(API_LINKS.chatbot_listar_fluxos).then((res) => {
        this.fluxos = res.data.fluxos;
      });
    },
    recursosDeCanaisValidos(recursos) {
      const recursos_validos = [];
      recursos.forEach((recurso) => {
        const obj = this.canais_possiveis.find((v) => v.value === recurso);
        if (obj) {
          recursos_validos.push(obj);
        }
      });
      this.canais_disponiveis =
        this.canais_disponiveis.concat(recursos_validos);
    }
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Chatbot"
      },
      {
        title: "Menus",
        route: "/painel/chatbot/menus"
      }
    ]);

    this.pegarRecursosDaConta();
    this.pegarFluxos();
    this.pegarConfiguracoesMenu();
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    fluxoOptions() {
      return this.fluxos.map((fluxo) => {
        return {
          value: fluxo.chatbot_fluxo_id,
          text: fluxo.cfl_titulo
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300&display=swap");

.b-button-custom {
  width: 142px;
}

.aut-body {
  border-radius: 5px;
  background-color: #fff;
}

.aut-area-exibir {
  display: flex;
  height: 100%;
  width: 100%;
}

.aut-header {
  height: 70px;
  width: 100%;
}

.aut-camp-area {
  width: 100%;
  min-height: 500px;
}

.container-dashboard {
  border-radius: 15px;
  background-color: #fff;
  padding: 20px 10px 20px 10px;
  margin-bottom: 15px;
}

.campo-estatistica {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.dropdown-form {
  padding: 3px 0px;
  width: 170px;
  height: 45px;
}

.dropdown-form:hover {
  background-color: rgb(247, 247, 247);
}

.button-container {
  border: 1px solid #e4e6ef;
  outline: none;
  color: #e3e4ed;
  background-color: transparent;
  border-radius: 0.42rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 38px;
}
.button-container.btn i {
  padding: 0 !important;
}

::v-deep .p-chips .p-chips-multiple-container {
  width: 100% !important;
}
::v-deep .p-chips-multiple-container {
  flex-wrap: wrap !important;
  gap: 0.5rem !important;
}

.menus-container {
  width: 100%;

  .header-menus-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    h3 {
      margin: 0;
    }
    .botoes-container {
      display: flex;
      justify-content: space-between;
    }
  }
  .flexer-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    min-height: 100% !important;
    align-items: center;
  }

  .flexer-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100% !important;
    > .form-group:first-child {
      margin-bottom: 0 !important;
    }
  }

  .flip-list-move {
    transition: transform 0.75s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .drag-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
    i {
      margin: 0;
      padding: 0;
    }
    > i:first-child {
      margin-right: 1px;
    }
  }
  .list-group {
    min-height: 20px;
    .list-group-item {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;
      background: #e8f3d6;
      border: none !important;
      border-radius: 8px !important;
      margin-bottom: 0.5rem !important;
      width: 100%;
      .inner-menu-item {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: 100%;
        justify-content: space-between;
      }
      &.inativo {
        background: #eeeeee;
        opacity: 0.5;
        &.editando {
          opacity: 1;
        }
      }
      .titulo {
        font-size: 0.9rem;
        font-weight: 600;
        color: #000;
        margin: 0;
      }
      .coluna-item {
        display: flex;

        flex-direction: column;
        gap: 0.5rem;
        .coluna-item-titulo {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
      i {
        cursor: pointer;
      }
      cursor: move;
    }
  }
  .novo-menu-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px dashed #000;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;
    width: 100%;
    height: 59px;
    &:hover {
      background: #eee;
      color: #000;
    }
  }

  .p-togglebutton.p-button {
    background: #e4e6ef;
    border: 1px solid #ced4da;
    color: #495057;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    text-transform: uppercase;
  }

  .p-togglebutton.p-button.p-highlight {
    background: #1bc5bd;
    border-color: #1bc5bd;
    color: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
  }
}
</style>
